import React from "react";
import Header from "../../../../components/header/Header";
import Home from "../../../../components/category/Home";
import Footer from "../../../../components/footer/Footer";
import banner from "../images/banner.png";
import banner1 from "../images/banner1.png";
import gif from "../images/booking.png";
import { graphql, Link } from "gatsby";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  const sidebar = {
    banner,
    banner1,
    gif,
    url: "https://www.a88wptk.com/KHNZB/2N9RR47/",
  };

  return (
    <div className="layout">
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Header category={data?.cms?.categories} allPosts={allPosts} engine={options} domain={data?.cms?.domain} />
      <Home data={data} domain={data?.cms?.domain} banner={sidebar} />
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query category($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "21") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "21") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "21", category_id: $slug) {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
